.promotion .swiper {
  width: 100%;
  height: 100%;
}

.promotion .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.promotion .swiper-slide img {
  display: block;
  width: 100%;
  height: 10rem;
  object-fit: cover;
  border-radius: 12px;
}

.promotion .swiper-slide {
  width: 60%;
  display: flex;
  gap: 1rem;
}
.promotion .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
  left: 0;
  width: 100%;
}

.promotion .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 19rem;
  top: 6rem;
  border: 1px solid black;
  background: white;
  border-radius: 50%;
  padding: 2rem;
  opacity: 0.7;
}

.promotion .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 6rem;
  top: 6rem;
  background: white;
  border-radius: 50%;
  padding: 2rem;
  border: 1px solid black;
  opacity: 0.8;
}

.promotion .swiper-button-next,
.swiper-button-prev {
  color: red;
}

.swiper-pagination-bullet-active {
  width: 2rem;
  border-radius: 4px;
}
@media only screen and (max-width: 1279px) {
  .promotion .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}
